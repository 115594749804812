<template>
  <div class="tw-w-full  tw-flex tw-flex-row  tw-items-center tw-justify-between lg:tw-px-32 ipad " style="background: transparent">
    <img @click="routeToHome" class="mobile-logo lg:tw-hidden tw-flex tw-cursor-pointer" src="@/assets/bluelogo.png" alt="logo"/>
    <h6 class="username tw-hidden md:tw-flex">{{getHeaderText.toString() === 'Dashboard' ? 'Hi '+developerData.firstname+',' : getHeaderText}}</h6>

    <v-col  sm="3" class="tw-hidden lg:tw-flex tw-items-center tw-flex-row ">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs"
               v-on="on" class="tw-flex tw-items-center tw-w-full tw-flex-row menu-drop-down" style="background-color: #FFFFFF" >
            <div class="tw-flex tw-items-start tw-justify-between tw-flex-row px-5 tw-w-full" >
              <v-badge
                  bottom
                  avatar
                  bordered
                  overlap
                  offset-x="10"
                  offset-y="10"
                  color="transparent"
              >
                <template v-slot:badge>
                    <icons :name="developerData.companyActivated ? 'verified': 'unverified'" />
                </template>
                  <v-img class="company-logo" :src="developerData.logo"></v-img>
              </v-badge>
              <h6 class="company-name tw-ml-3">{{developerData.companyName}}</h6>
              <v-icon right>mdi-chevron-down</v-icon>
            </div>
          </div>
        </template>
        <v-list>
          <v-list-item @click="selectedAction(item)"
                       v-for="(item, index) in items"
                       :key="index" >
            <v-list-item-title style="color: black" >{{ item}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>


    </v-col>
    <div class="tw-flex lg:tw-hidden">
      <v-icon  :color="companyColor" size="24" class="" @click="drawer = !drawer">
        mdi-view-dashboard-outline
      </v-icon>
    </div>
    <v-navigation-drawer color="#001127" v-model="drawer"
        absolute
        temporary
        right>

      <div class="tw-flex tw-p-5 tw-w-full tw-justify-end" style="background: #001127">
        <v-icon color="#FFFFFF" @click="drawer = !drawer">mdi-window-close</v-icon>
      </div>
      <mobile-avatar />
      <mobile-side-menu />
    </v-navigation-drawer>
  </div>
</template>

<script>

import {logout} from "../../services/api/AuthApiService";
import MobileAvatar from "./MobileAvatar";
import MobileSideMenu from "./MobileSideMenu";
import Icons from "./Icons";
import {mapGetters} from "vuex";

export default {
  name: "DeveloperHeader",
  components: {Icons, MobileSideMenu, MobileAvatar},
  props:{
    companyColor:{
      type: [String],
      default: "#004AAD"
    },
    developerFirstName: [String],
    companyName : [String],
    companyLogo: [String]
  },
  data(){
    return{
      items:["Profile","Logout"],
      drawer: false,
      verified: false
    }
  },
  computed:{
    getHeaderText(){
      return this.$route.name
    },
      ...mapGetters("developerData",["developerData"])
  },
  methods:{
    selectedAction(item){
      if (item === 'Logout'){
        this.handleLogout()
      }
      else {
        if (this.$route.name !== 'Settings') {
          this.$router.push({name: 'Settings'})
          sessionStorage.setItem("dashboardComponent", 'setting');
          this.$store.dispatch("dashboardComponent/setCurrentComponent", 'setting');
          window.location.reload()
        }
      }
    },
    handleLogout() {
      logout()
    },
    routeToHome(){
      if (this.$route.name === 'HomePage'){
        window.open(process.env.VUE_APP_MAIN_FRONTEND_URL,"_self")
      }
      else {
        this.$router.push({name:'HomePage'})
      }
    }
  }
}
</script>

<style scoped lang="scss">
.logo-col{
  background-color: #1E5770;
  @media screen and (min-width: 770px) {
    padding-left: 3rem;
  }
}

.username{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1D262D;
}

.menu-drop-down{
  height: 52px;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.company-logo{
  border: 1px solid #0547AD;
  box-sizing: border-box;
  border-radius: 50%;
  width: 29px;
  height: 29px;
}

.company-name{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #4F4F4F;
}

::v-deep .v-badge--bordered .v-badge__badge::after {
  border: none!important;
}

.ipad{
  @media screen and (max-width: 1024px){
    justify-content: space-around;
  }
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    padding: 1rem;
  }
}

.mobile-logo{
  width: 116.8px;
  height: 32px;
}
</style>