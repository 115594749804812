<template>
<div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-p-5" style="background:#001127">
<img class="avatar" :src="developerData.logo" alt="image" v-if="developerData.logo"/>
  <avatar :avatar="avatar" :color="color" v-else />
  <div class="tw-flex tw-pl-2 tw-flex-col">
    <h6 class="user-name" :style="{color:color}">{{developerData.companyName}}</h6>
  </div>
</div>
</template>

<script>
import Avatar from "./Avatar";
import defaultPic from "@/assets/default-pic.png"
import {mapGetters} from "vuex";
export default {
  name: "MobileAvatar",
  components: {Avatar},
  props:{
    avatar:{
      type : [String],
      default : "R"
    },
    avatarImage:{
      type: [String],
      default: defaultPic
    },
    color:{
      type: [String],
      default: "#FFFFFF"
    },
    userName:{
      type: [String],
      default: "Kelly"
    },
    userEmail:{
      type: [String],
      default: "elly@example.com"
    },
  },
  computed:{
    ...mapGetters("developerData",["developerData"])
  },
}
</script>

<style scoped lang="scss">
.avatar{
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #001127;

}
.user-name{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
}
.user-email{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
  color: #BDBDBD;
}
</style>