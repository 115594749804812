<template>
<div class="dashboard" >
  <loader :loading="loading" v-if="loading" />
  <v-col v-else sm="12" class="tw-flex tw-w-full tw-justify-between  dashboard-body" style="background: #F5F7F9">
    <side-menu/>
    <center-component/>
  </v-col>
</div>
</template>

<script>



import {mapGetters} from "vuex";
import CenterComponent from "../components/reuseables/CenterComponent";
import SideMenu from "../components/reuseables/SideMenu";
import Loader from "../components/reuseables/Loader";
// import VueJwtDecode from "vue-jwt-decode";


export default {
  name: "DeveloperDashboard",
  components: {Loader, SideMenu, CenterComponent},
  data(){
    return{
      loading : false
    }
  },
  computed:{
    ...mapGetters("developerData",["developerData"])
  },
  async created() {
    this.loading = true
    let userData = JSON.parse(sessionStorage.getItem("userData"))
    let data = {}
    data.email = userData.email
    await  this.$store.dispatch("developerData/setDeveloperData",data)
        .then(()=>{

          if (!this.developerData.companyActivated){
            this.$router.push({name : 'AwaitingVerification'})
          }
          this.loading = false})

  }
}
</script>

<style scoped lang="scss">
.dashboard{
  display: flex;
  background-color: #F5F7F9;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
}

.dashboard-body{
  display: flex;
  min-height: 93.7vh;
  flex-direction: row;
  padding: 0 !important;
  @media screen and (max-width: 1450px){
    min-height: 93vh;
  }
  @media screen and (max-width: 1370px){
    min-height: 87vh;
  }
}
</style>