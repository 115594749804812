<template>
<v-col sm="12" lg="10" class="body tw-m-0 tw-p-0">
  <developer-header/>
  <keep-alive>
    <router-view></router-view>
  </keep-alive>
</v-col>
</template>

<script>
import DeveloperHeader from "./DeveloperHeader";
export default {
  name: "CenterComponent",
  components: {DeveloperHeader}
}
</script>

<style scoped lang="scss">
.body{
  background-color: #FDFEFF;
}
</style>