<template>
    <v-col sm="2" class="side-menu tw-pl-8 tw-m-0 tw-pr-0">
      <img @click="$router.push({name:'HomePage'})" class="logo  tw-mb-16 tw-mt-5 tw-cursor-pointer" src="@/assets/whitelogo.png"  alt="logo"/>
      <div
          :class="selectedTab === tab ? 'selected' : 'select-div'"
          class="my-3   tw-cursor-pointer"
          @click="handleSelectedTab(tab)"
          v-for="(tab, index) in tabs"
          :key="index"
      >
        <v-icon v-if="tab === selectedTab" :color="companyColor"
        >mdi-power-on</v-icon
        >
        <icons
            :name="tab"
            :color="selectedTab === tab ? companyColor : '#FFFFFF'"
        />
        <label
            class="pl-4 text-capitalize"
            :style="{ color: selectedTab === tab ? companyColor : '#FFFFFF' }"
        >{{ tab }}</label
        >
      </div>
      <div class="tw-flex  tw-items-center tw-flex-row tw-mt-20 tw-cursor-pointer" @click="handleLogout">
        <icons color="#FFFFFF" name="logout" />
        <span class="pl-4 text-capitalize" :style="{ color: '#FFFFFF' }">logout</span>
      </div>
    </v-col>
</template>

<script>
import Icons from "./Icons";
import { mapGetters } from "vuex";
import {logout} from "../../services/api/AuthApiService";
export default {
  name: "SideMenu",
  components: { Icons },
  props: {
    companyColor: {
      type: [String],
      default: "#004AAD",
    },
  },
  data() {
    return {
      selectedTab: "dashboard",
      tabs: [
        "dashboard",
          "transport companies",
        "wallet",
        "documentation",
        "community",
        "setting"
      ],
    };
  },
  computed: {
    ...mapGetters("dashboardComponent", ["currentComponent"]),
    routeName() {
      return this.$route.name;
    },
  },
  methods: {
    handleSelectedTab(tab) {
      this.selectedTab = tab;
      if (this.selectedTab === "dashboard") {
        if (this.$route.name !== 'Dashboard')
        this.$router.push({ name: "Dashboard" });
      }
      else if (this.selectedTab === "transport companies") {
        if (this.$route.name !== 'Transport Companies')
          this.$router.push({ name: "Transport Companies" });
      }
      else if (this.selectedTab === "wallet") {
        if (this.$route.name !== 'Wallet')
          this.$router.push({ name: "Wallet" });
      }
      else if (this.selectedTab === "community") {
        if (this.$route.name !== 'Community')
          this.$router.push({ name: "Community" });
      }
      else if (this.selectedTab === "setting") {
        if (this.$route.name !== 'Settings')
          this.$router.push({ name: "Settings" });
      }
      else if (this.selectedTab === "documentation"){
        if (this.$route.name !== 'Documentation'){
          this.$router.push({name:'Documentation'})
        }
      }

      sessionStorage.setItem("dashboardComponent", tab);
      this.handleSetCurrentComponent();
    },
    handleSetCurrentComponent() {
      let component = sessionStorage.getItem("dashboardComponent");
      this.$store.dispatch("dashboardComponent/setCurrentComponent", component);
    },
    handleLogout(){
      logout()
    }
  },
  created() {
    let component = sessionStorage.getItem("dashboardComponent");
    if (!component) {
      sessionStorage.setItem("dashboardComponent", "dashboard");
      this.handleSetCurrentComponent();
    } else {
      this.selectedTab = component;
      this.$store.dispatch("dashboardComponent/setCurrentComponent", component);

      this.handleSelectedTab(this.selectedTab);
    }
  },
};
</script>

<style scoped lang="scss">
.side-menu {
  display: flex;
  background: #001127;
  width: 100%;
  min-height: 100vh;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  flex-direction: column;
  z-index: 5;
  @media screen and (max-width: 1264px) {
    display: none;
  }
}

.select-div {
  height: 50px;
  background: #001127;
  width: auto;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: white;
  flex-direction: row;
}
.select-div:hover {
  height: 50px;
  background: #111112;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
}

.selected {
  height: 50px;
  background: #FDFEFF;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  border: none;
}

.logo{
  width: 95.79px;
  height: 19.56px;
}
</style>
